import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { ready } from '#js/components/utils'

ready(function (event) {
  document.querySelectorAll('.geocoder').forEach(function (element) {
    initGeocoder(element, element.dataset.value, element.dataset.referenceId, element.dataset.labelId, element.dataset.forLabelId)
  })
})

export function initGeocoder (element, initialValue, referenceInputId, labelId, forLabelId) {
  const geocoder = new MapboxGeocoder({
    accessToken: window.mapboxApiKey,
    proximity: {
      longitude: 10.451526,
      latitude: 51.165691
    },
    countries: 'DE',
    language: window.language
  })
  geocoder.addTo(element)
  geocoder._inputEl.autocomplete = 'off'
  if (initialValue) {
    geocoder._inputEl.value = initialValue
  }
  if (referenceInputId) {
    const referenceInput = document.getElementById(referenceInputId)
    geocoder.on('result', (results) => {
      referenceInput.value = results.result.place_name
    })
    // Adjust hidden input value for every change of input by the user, not just on result event.
    element.querySelector('.mapboxgl-ctrl-geocoder--input').addEventListener('input', (e) => {
      referenceInput.value = e.target.value
    })
  }
  if (forLabelId) {
    geocoder._inputEl.id = forLabelId
  }
  if (labelId) {
    geocoder.container.appendChild(document.getElementById(labelId))
  }
  return geocoder
}
